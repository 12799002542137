import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDove } from '@fortawesome/pro-light-svg-icons';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const ValuesBrief = () => {
  const styles = useStyles();

  return (
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10} lg={8}>
        <Paper className={styles.paper} elevation={4}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          >
            <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h3"
              align="center"
              className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
                Building software with purpose
              <FontAwesomeIcon icon={faDove} size="lg" className={styles.headerIcon}/>
              <Typography
                variant="caption"
                component="p"
                align="right"
                className={styles.headerSubText}>
                {`If you are working on something that you really care about,
                  you don’t have to be pushed – Steve Jobs`}
              </Typography>
            </Typography>
            </Grid>

            <Grid item md={10} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                component="p"
                className={styles.headingMessageJustify} align="justify">
              {`This software was built during the Covid pandemic a difficult time in global history for so many reasons. We believe it's important to have values. In these changeable times
              our values help us keep focus on what's important.`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

ValuesBrief.propTypes = { hash: string };

export default ValuesBrief;
